import * as React from "react";
import "./Footer.scss";
import { FunctionComponent } from "react";
import { Link, Match } from "@reach/router";
import FooterContent from "./FooterContent";

type Props = {
  videos: boolean;
  extraClass: string;
};

const Footer: FunctionComponent<Props> = (props) => {
  return (
    <Match path="/">
      {({ match }) => (
        match && props.videos ?
          null
          :
          <footer className={`footer ${props.extraClass}`}>
            <FooterContent />
          </footer>
      )}
    </Match>
  );
};

export default Footer;
