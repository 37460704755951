import * as React from "react";
import { Link, Match } from "@reach/router";
import "./Header.scss";
import { Component } from "react";
import { connect } from "react-redux";
import { toggleCart } from "./Reducers/GeneralReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "./img/logo.png";

type State = {
  showMobileMenu: boolean;
};

type Props = {
  videos: boolean;
  show: boolean;
  toggleCart: () => void;
  showHeader: boolean;
};

class Header extends Component<Props, State> {
  state: State = {
    showMobileMenu: false,
  };

  setMobileMenuActive(val: boolean) {
    this.setState({
      showMobileMenu: val,
    });
  }

  handleNavbarMenuClick(e: React.MouseEvent<HTMLDivElement>) {
    if (e.target instanceof HTMLAnchorElement && e.target.classList.contains("navbar-item")) {
      this.setMobileMenuActive(false);
      //document.body.scrollTop = 0;
      //document.documentElement.scrollTop = 0;
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { show, toggleCart } = this.props;
    const { showMobileMenu } = this.state;
    return (
      <>
        <Match path="/">
          {({ match }) =>
            (match && this.props.videos) || !this.props.showHeader ? null : (
              <header className="header" style={{}}>
                <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
                  <div className="navbar-brand">
                    <a href="/home">
                      <img src={logo} className="logo" alt="Get-Outt Logo" />
                      {false && (
                        <h1>
                          <strong>Get-outt</strong>
                        </h1>
                      )}
                    </a>
                    <a
                      role="button"
                      className={"cart cart-mobile is-active"}
                      onClick={e => {
                        e.preventDefault();
                        toggleCart();
                      }}
                    >
                      <FontAwesomeIcon icon="shopping-cart" />
                    </a>
                    <a
                      role="button"
                      className={"navbar-burger burger " + (showMobileMenu ? "is-active" : "")}
                      aria-label="menu"
                      aria-expanded={showMobileMenu}
                      onClick={e => {
                        e.preventDefault();
                        this.setMobileMenuActive(!showMobileMenu);
                      }}
                    >
                      <span aria-hidden="true" />
                      <span aria-hidden="true" />
                      <span aria-hidden="true" />
                    </a>
                  </div>
                  <div
                    onClick={() => this.handleNavbarMenuClick}
                    className={"navbar-menu " + (showMobileMenu ? "is-active" : "")}
                  >
                    <div className="navbar-start">
                      <Match path="/producten/:productId">
                        {({ match }) => (
                          <Link to="/producten" className={match ? "navbar-item is-active" : "navbar-item"}>
                            Producten
                          </Link>
                        )}
                      </Match>
                      <Match path="/oefeningen/:selectedExerciseId">
                        {({ match }) => (
                          <Link to="/oefeningen" className={match ? "navbar-item is-active" : "navbar-item"}>
                            Oefeningen
                          </Link>
                        )}
                      </Match>
                      <Match path="/nieuws/:articleId">
                        {({ match }) => (
                          <Link to="/nieuws" className={match ? "navbar-item is-active" : "navbar-item"}>
                            Nieuws
                          </Link>
                        )}
                      </Match>
                      <Match path="/over-ons">
                        {({ match }) => (
                          <Link to="/over-ons/team" className={match ? "navbar-item is-active" : "navbar-item"}>
                            Over Ons
                          </Link>
                        )}
                      </Match>
                      <Match path="/home">
                        {({ match }) =>
                          match && (
                            <span className="slogan">
                              <i>We move you to get out, to become and stay fit!</i>
                            </span>
                          )
                        }
                      </Match>
                      <Match path="/producten/:productId">
                        {({ match }) =>
                          match && (
                            <span className="slogan">
                              <i>Fitness-mogelijkheden in openlucht, ook bij u thuis.</i>
                            </span>
                          )
                        }
                      </Match>
                      <Match path="/oefeningen/:selectedExerciseId">
                        {({ match }) =>
                          match && (
                            <span className="slogan">
                              <i>Iedere maand: oefeningen in detail uitgelegd.</i>
                            </span>
                          )
                        }
                      </Match>
                      <Match path="/nieuws/:articleId">
                        {({ match }) =>
                          match && (
                            <span className="slogan">
                              <i>Relevante nieuwsfeiten omtrent gezondheid en fitheid.</i>
                            </span>
                          )
                        }
                      </Match>
                      <Match path="/over-ons/:test">
                        {({ match }) =>
                          match && (
                            <span className="slogan">
                              <i>Get-outt: een bedrijf met een missie.</i>
                            </span>
                          )
                        }
                      </Match>
                      <Match path="/contact">
                        {({ match }) =>
                          match && (
                            <span className="slogan">
                              <i>Get-outt: een bedrijf met een missie.</i>
                            </span>
                          )
                        }
                      </Match>
                      <Match path="/faq">
                        {({ match }) =>
                          match && (
                            <span className="slogan">
                              <i>Get-outt: een bedrijf met een missie.</i>
                            </span>
                          )
                        }
                      </Match>
                    </div>
                  </div>
                  <a
                    role="button"
                    className={"cart cart-desktop is-active"}
                    onClick={e => {
                      e.preventDefault();
                      toggleCart();
                    }}
                  >
                    <FontAwesomeIcon icon="shopping-cart" />
                  </a>
                </nav>
              </header>
            )
          }
        </Match>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  show: state.general.showCart,
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleCart: () => dispatch(toggleCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
