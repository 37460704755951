import { Product, CartItem } from "../Data";

const ADD_TO_CART = "ADD_TO_CART";
export function addToCart(product: Product) {
  return {
    type: ADD_TO_CART,
    product,
  };
}

const RESET_CART = "RESET_CART";
export function resetCart() {
  return {
    type: RESET_CART,
  };
}

const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export function removeFromCart(id: string) {
  return {
    type: REMOVE_FROM_CART,
    id,
  };
}

const CHANGE_AMOUNT = "CHANGE_AMOUNT";
export function changeAmount(id: string, amount: number) {
  return {
    type: CHANGE_AMOUNT,
    id,
    amount,
  };
}

type State = {
  items: CartItem[];
};

export const cart = (
  state: State = {
    items: [],
  },
  action: any
) => {
  switch (action.type) {
    case ADD_TO_CART:
      const exists = state.items.find(i => i.product.id === action.product.id)

      if(exists) {
        return {
          ...state,
          items: state.items.map(i => {
            if(i.product.id === action.product.id){
              i.amount += 1;
            }

            return i
          })
        }
      }

      return {
        ...state,
        items: [
          ...state.items,
          {
            product: action.product,
            amount: 1,
          },
        ],
      };

    case REMOVE_FROM_CART:
      return {
        ...state,
        items: state.items.filter(item => item.product.id !== action.id),
      };

    case RESET_CART:
        return {
          ...state,
          items: [],
        };

    case CHANGE_AMOUNT:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.product.id === action.id) {
            item.amount = action.amount;
          }
          return item;
        }),
      };

    default:
      return state;
  }
};
