import * as React from "react";
import "./News.scss";
import { Component } from "react";
import { RouteComponentProps, navigate, Link } from "@reach/router";
import Page from "./Page";
import { Data, Article } from "./Data";
import Markdown from "./Markdown";

type Props = {
  data: Data;
  articleId?: string;
};

type State = {
  articles: Article[];
};

class News extends Component<Props & RouteComponentProps, State> {
  state: State = {
    articles: [],
  };

  async componentDidMount() {
    let articles = await this.props.data.getArticles();
    articles = articles.reverse();
    this.setState({ articles });
    if (!this.props.articleId) {
      const [firstArticle] = articles;

      return navigate(`/nieuws/${firstArticle.id}`);
    }
  }

  componentWillReceiveProps(nextProps: Readonly<Props & RouteComponentProps>, nextContext: any) {
    if (!nextProps.articleId) {
      const [firstArticle] = this.state.articles;

      return navigate(`/nieuws/${firstArticle.id}`);
    }
  }

  toReadableMonth(str: any) {
    let rm = "";

    switch (str.substr(-2, 2)) {
      case "00":
        rm += "Januari ";
        break;
      case "01":
        rm += "Feburari ";
        break;
      case "02":
        rm += "Maart ";
        break;
      case "03":
        rm += "April ";
        break;
      case "04":
        rm += "Mei ";
        break;
      case "05":
        rm += "Juni ";
        break;
      case "06":
        rm += "Juli ";
        break;
      case "07":
        rm += "Augustus ";
        break;
      case "08":
        rm += "September ";
        break;
      case "09":
        rm += "Oktober ";
        break;
      case "10":
        rm += "November ";
        break;
      case "11":
        rm += "December ";
        break;
      default:
        rm = "";
        break;
    }

    rm += str.substr(0, 4);

    return rm;
  }

  render() {
    const { articles } = this.state;
    const { articleId } = this.props;
    let articleInfo;

    if (articleId) {
      const article = articles.find(article => article.id === articleId);
      if (article) {
        const { id, title, text, image, link, link_description } = article;
        articleInfo = (
          <div key={id} className="box with-shadow product">
            <h2 className="title is-3">{title}</h2>
            <div className="columns">
              <div className="column is-6">
                <figure className="image is-3by2" style={{ marginBottom: "20px" }}>
                  <img src={image} alt={title} />
                </figure>
              </div>
              <div className="column is-6">
                <div style={{ color: "#5a6769" }}>
                  <Markdown>{text}</Markdown>
                </div>
                {link && (
                  <div>
                    <a href={link} target="_blank">
                      {link_description}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }
    }

    const months = new Map();
    articles.forEach(article => {
      const yearMonth =
        new Date(article.date).getFullYear() +
        (new Date(article.date).getMonth() < 10 ? "0" : "") +
        new Date(article.date).getMonth();

      const collection = months.get(yearMonth);
      if (!collection) {
        months.set(yearMonth, [article]);
      } else {
        collection.push(article);
      }
    });

    const keys = Array.from(months.keys());
    return (
      <Page title="Nieuws en Media">
        <div className="columns">
          <div className="column is-2-tablet is-2-desktop">
            <aside className="menu">
              {Array.from(months.values()).map((articles, key) => {
                return (
                  <div key={key}>
                    <strong className="menu-label">{this.toReadableMonth(keys[key])}</strong>
                    <ul className="menu-list">
                      {articles.map((article: Article) => {
                        return (
                          <li key={article.id}>
                            <Link to={`/nieuws/${article.id}`} className={articleId === article.id ? "is-active" : ""}>
                              {article.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </aside>
          </div>
          <div className="column">{articleInfo}</div>
        </div>
      </Page>
    );
  }
}

export default News;
