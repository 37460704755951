import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "@reach/router";
import { Data } from "./Data";
import "./CookieItem.scss";
import Toggle from 'react-toggle'
import "react-toggle/style.css"


type Props = {
  data: Data;
  name: string;
  id: string;
  extra: string;
};

type State = {
  expand: boolean;
  accepted: boolean;
};

export default class CookieItem extends Component<Props & RouteComponentProps, State> {
  state: State = {
    expand: false,
    accepted: true,
  };

  async componentDidMount() {
    //const landing = await this.props.data.getLanding();
    //this.setState({ landing: landing });
    this.setState({accepted: this.getInitualValue()})
  }

  getInitualValue() {
    let value = localStorage.getItem(this.props.id);

    if(value === null) {
      localStorage.setItem(this.props.id, "true");
      value = "true";
    }

    return value === "true"
  }

  toggleExpand() {
    this.setState({expand: !this.state.expand});
  }

  toggleAccepted() {
    localStorage.setItem(this.props.id, (!this.state.accepted).toString());
    this.setState({accepted: !this.state.accepted})
  }

  render() {
    let { expand } = this.state;

    return (
      <div className="cookie-container">
        <div className="cookie-box">
          <Toggle
            defaultChecked={this.getInitualValue()}
            onChange={() => this.toggleAccepted()} />
          <div className="cookie" onClick={() => this.toggleExpand()}>
            {this.props.name}
          </div>
        </div>
        {expand ? <div style={{marginTop: "5px"}}>
          {this.props.extra}
        </div> : null }
      </div>
    );
  }
}
