import * as React from "react";
import { Component } from "react";
import { RouteComponentProps, globalHistory } from "@reach/router";
import { Data, Privacy as PrivacyData } from "./Data";
import Page from "./Page";
import Markdown from "./Markdown";


type Props = {
  data: Data;
};

type State = {
  privacy: PrivacyData;
};

export default class Privacy extends Component<Props & RouteComponentProps, State> {
  state: State = {
    privacy: {
      text: "",
      id: "",
      email: ""
    },
  };

  async componentDidMount() {
    const privacy = await this.props.data.getPrivacy();
    this.setState({ privacy: privacy });
  }

  render() {
    let { privacy } = this.state;

    return (
      <Page title="Privacy">
        <div className="columns">
          <div className="column">
            <Markdown>{privacy.text}</Markdown>
            <strong>Contact: </strong> <a href={`mailto:${privacy.email}`}>{privacy.email}</a>
          </div>
        </div>
      </Page>
    );
  }
}
