import { API_URI, CartItem, Product, Service } from "./Data";

//const STRAPI_URI = "http://localhost:1337";
//const STRAPI_URI = "https://api.staging.get-outt.be";
//const STRAPI_URI = "https://get-outt-api.azurewebsites.net";

function getStrapiUri(endpoint: string) {
  return `${API_URI}${endpoint}`;
}

export const getHeaders = async () => {
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
};

const POST = async (endpoint: string, body: object) => {
  return fetch(endpoint, {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify(body),
  }).then(response => response.json());
};

export function finishCheckout(
  email: string,
  firstname: string,
  lastname: string,
  items: CartItem[],
  btw: string,
  remarks: string,
  choice: string,
  address: string,
  postal: string,
  city: string,
  mobile: string,
  country: string,
) {
  POST(getStrapiUri("/teamleader/checkout"), {
    email,
    firstname,
    lastname,
    items,
    btw,
    remarks,
    choice,
    address,
    postal,
    city,
    mobile,
    country
  });
  sendMail(email, firstname);
}

export function finishRent(
  email: string,
  firstname: string,
  lastname: string,
  product: Product,
  services: Service[],
  start: string,
  end: string,
  coupon: string,
  delivery: string,
  postal: string,
) {
  sendMail(email, firstname);
  POST(getStrapiUri("/teamleader/rent"), {
    email,
    firstname,
    lastname,
    product: product.name,
    services,
    start,
    end,
    coupon,
    delivery,
    postal,
  });
}

export async function contact(email: string, firstname: string, lastname: string, type: string, message: string, number: string) {
  if (!(await checkEmail(email)))
    return false;

  sendMail(email, firstname);
  const response = POST(getStrapiUri("/teamleader/contact"), {
    email,
    firstname,
    lastname,
    type,
    message,
    number
  }).then(response => {
    debugger;
  });

  return true;
}

async function checkEmail(email: string) {
  const res = await POST(getStrapiUri("/checkemails"), {
      email: email,
    },
  );

  return res.success;
}

function sendMail(email: string, firstName: string) {
  POST(getStrapiUri("/email"), {
      "to": `${email}`,
      "subject": "Bevestiging van uw aanvraag",
      "text": `Dag ${firstName}, Hartelijk dank voor uw aanvraag/bestelling/aankoop. We hebben deze goed ontvangen en wordt onmiddellijk verder verwerkt. Eén van onze medewerkers zal met u in contact treden (via email) voor de verdere afhandeling. Met vriendelijke groeten, Tobias Get-outt (een Dri3m-afdeling)`,
      "html": `Dag ${firstName}, <br><br>Hartelijk dank voor uw aanvraag/bestelling/aankoop. We hebben deze goed ontvangen en wordt onmiddellijk verder verwerkt. <br><br>Eén van onze medewerkers zal met u in contact treden (via email) voor de verdere afhandeling.<br><br> Met vriendelijke groeten, <br>Tobias <br><br><br>Get-outt <span style="font-size: 12px">(een Dri3m-afdeling)</span>`,
    },
  );
}
