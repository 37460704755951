import * as React from "react";
import "./TeamList.scss";
import { Component } from "react";
import { RouteComponentProps, Link } from "@reach/router";
import Sidebar from "./Sidebar";
import { Data, Employee } from "../Data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Page from "../Page";
import { contact } from "../Api";

type Props = {
  data: Data;
};

type State = {
  team: Employee[];
  email: string;
  firstname: string;
  lastname: string;
  number: string;
  type: string;
  message: string;
};

export default class Mission extends Component<Props & RouteComponentProps, State> {
  state: State = {
    team: [],
    email: "",
    firstname: "",
    lastname: "",
    number: "",
    type: "Interesse",
    message: "",
  };

  async componentDidMount() {
    const team = await this.props.data.getTeam();
    this.setState({ team: team });
  }

  render() {
    const { team, email, firstname, lastname, type, message, number } = this.state;

    return (
      <Page title="">
        <div className="columns">
          <div className="column is-3-tablet is-2-desktop">
            <Sidebar team={team}/>
          </div>
          <div className="column is-offset-1-desktop">
            <div>
              <h2 className="title is-3">Contacteer Ons</h2>
              <h3 className="subtitle is-5">Laat een bericht na / Stel een vraag</h3>
              <form
                onSubmit={async e => {
                  e.preventDefault();
                  const success = await contact(email, firstname, lastname, type.charAt(0).toUpperCase() + type.slice(1), message, number);
                  if (success) {
                    alert("Uw verzoek werd goed ontvangen. We nemen spoedig contact met u op.");
                  } else {
                    alert("Uw email is niet geldig, gelieve dit te controleren.");
                  }
                }}
              >
                <div className="field">
                  <label className="label">Mijn Emailadres</label>
                  <div className="control has-icons-left">
                    <input
                      className="input"
                      type="email"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="Ongeldig email-adres opgegeven."
                      placeholder="Bvb. naam@email.be"
                      required
                      autoFocus
                      value={email}
                      onChange={e =>
                        this.setState({
                          email: e.target.value,
                        })
                      }
                    />
                    <span className="icon is-small is-left">
                      <FontAwesomeIcon icon="envelope"/>
                    </span>
                  </div>
                </div>
                <div className="field">
                  <label className="label">Voornaam</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      required
                      value={firstname}
                      onChange={e =>
                        this.setState({
                          firstname: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Achternaam</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      required
                      value={lastname}
                      onChange={e =>
                        this.setState({
                          lastname: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Telefoonnummer</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      value={number}
                      onChange={e =>
                        this.setState({
                          number: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Soort Bericht</label>
                  <div className="control has-icons-left">
                    <select
                      className="input"
                      defaultValue={window.location.pathname.split("/")[2] ? window.location.pathname.split("/")[2] : ""}
                      required
                      onChange={e =>
                        this.setState({
                          type: e.target.value,
                        })
                      }
                    >
                      <option value="" disabled>
                        Interesse, Offerte, Vraag, klacht, ...
                      </option>
                      <option value="interesse">Interesse</option>
                      <option value="offerte">Offerte</option>
                      <option value="vraag">Vraag</option>
                      <option value="klacht">Klacht</option>
                    </select>
                    <span className="icon is-small is-left">
                      <FontAwesomeIcon icon="info"/>
                    </span>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Bericht</label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      onChange={e =>
                        this.setState({
                          message: e.target.value,
                        })
                      }
                      placeholder="Vraag, feedback, ..."
                      value={message}
                      required
                    />
                  </div>
                </div>

                <div className="field is-grouped">
                  <div className="control">
                    <button className="button is-link">Verzenden</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
