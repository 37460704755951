import * as React from "react";
import "./App.scss";
import { Component, FunctionComponent } from "react";
import { Router } from "@reach/router";

import { Provider } from "react-redux";
import { applyMiddleware, createStore, compose } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import getoutt from "./Reducers";

import Home from "./Home";
import Products from "./Products";
import Rent from "./Rent";
import Exercises from "./Exercises";
import News from "./News";
import Header from "./Header";
import Footer from "./Footer";
import Team from "./about/Team";
import Mission from "./about/Mission";
import Contact from "./about/Contact";

import { Data, Faq, General, Landing } from "./Data";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faInfo, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import Checkout from "./Checkout";
import Privacy from "./Privacy";
import Landingpage from "./Landingpage";
import FaqComponent from "./about/Faq";

library.add(faEnvelope);
library.add(faInfo);
library.add(faShoppingCart);

const store = createStore(
  getoutt,
  composeWithDevTools()
  //applyMiddleware(thunk, logger)
  // other store enhancers if any
);

type Props = {
  data: Data;
};

type State = {
  landing: Landing;
  general?: General;
};

export default class App extends Component<Props, State> {
  state: State = {
    landing: {
      slowmotion: null,
      id: "",
      movie: null,
      transpalog: "",
    },
    general: undefined,
  };

  async componentDidMount() {
    const landing = await this.props.data.getLanding();
    const general = await this.props.data.getGeneral();

    this.setState({ landing: landing, general: general });
  }

  render() {
    const data = this.props.data;

    let videos = this.state.landing.movie !== null && this.state.landing.slowmotion !== null;

    return (
      <Provider store={store}>
        <Header videos={videos} showHeader={this.state.general !== undefined} />
        {this.state.general ? (
          <div className="page-content">
            <Router>
              {videos ? (
                <>
                  <Landingpage path="/" data={data} landing={this.state.landing} />
                  <Home path="/home" data={data} />
                </>
              ) : (
                <>
                  <Home path="/" data={data} />
                  <Home path="/home" data={data} />
                </>
              )}
              <Products path="/producten/:productId" data={data} />
              <Products path="/producten" data={data} />
              <Rent path="/huur/:productId" data={data} />
              <Exercises path="/oefeningen/:selectedExerciseId" data={data} />
              <Exercises path="/oefeningen" data={data} />
              <News path="/nieuws/:articleId" data={data} />
              <News path="/nieuws" data={data} />
              <Team path="/over-ons/team" data={data} />
              <Mission path="/over-ons/missie" data={data} />
              <Contact path="/contact" data={data} />
              <Contact path="/contact/vraag" data={data} />
              <Contact path="/contact/klacht" data={data} />
              <FaqComponent path="/faq" data={data} />
              <Privacy path={"/privacy"} data={data} />
            </Router>
            <Checkout />
          </div>
        ) : (
          <div className="constuction">
            <h1>Under construction</h1>
          </div>
        )}
        <Footer videos={videos} extraClass={this.state.general ? "" : "footer-construction"} />
      </Provider>
    );
  }
}
