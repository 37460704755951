import * as React from "react";
import "./Exercises.scss";
import { Component } from "react";
import { Link, navigate, RouteComponentProps } from "@reach/router";
import { Data, Exercise, Product } from "./Data";
import Page from "./Page";
import Markdown from "./Markdown";

type Props = {
  data: Data;
  selectedExerciseId?: string;
};

type State = {
  exercises: Exercise[];
  products: Product[];
  filterProductId: string;
};

class Exercises extends Component<Props & RouteComponentProps, State> {
  state: State = {
    exercises: [],
    products: [],
    filterProductId: "",
  };

  async componentDidMount() {
    const results = await Promise.all([await this.props.data.getExercises(), await this.props.data.getProducts()]);

    const [exercises, products] = results;
    this.setState({
      exercises,
      products,
    });

    if (!this.props.selectedExerciseId) {
      const [firstExercise] = exercises;

      return navigate(`/oefeningen/${firstExercise.id}`);
    }
  }

  componentWillReceiveProps(nextProps: Readonly<Props & RouteComponentProps>, nextContext: any) {
    if (!nextProps.selectedExerciseId) {
      const [firstExercise] = this.state.exercises;

      return navigate(`/oefeningen/${firstExercise.id}`);
    }
  }

  render() {
    const { exercises, products, filterProductId } = this.state;
    const { selectedExerciseId } = this.props;
    let exerciseInfo;
    let exerciseProducts;

    if (selectedExerciseId) {
      const selectedExercise = exercises.find(exercises => exercises.id === selectedExerciseId);
      if (selectedExercise) {
        const { name, imageUrls, description, musclegroups, youtube_code } = selectedExercise;
        exerciseProducts = (
          <>
            {localStorage.getItem("youtube") === "true" && youtube_code && (
              <div>
                <iframe
                  width="560"
                  height="375"
                  src={"https://www.youtube.com/embed/" + youtube_code}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                />
              </div>
            )}
            {musclegroups.length > 0 && <h3 className="title is-4">Spiergroepen</h3>}
            {musclegroups.length > 0 && (
              <div className="content">
                <ul>
                  {selectedExercise.musclegroups.map(muscle => {
                    return (
                      <li key={muscle.id} style={{ marginBottom: "1rem" }}>
                        {muscle.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {selectedExercise.products.length > 0 && <h3 className="title is-4">Bijhorende Producten</h3>}
            {selectedExercise.products.length > 0 && (
              <div className="content">
                <ul>
                  {selectedExercise.products.map(product => {
                    const { id, name } = product;
                    return (
                      <li key={selectedExercise.id + id} style={{ marginBottom: "1rem" }}>
                        <div className="level">
                          <div className="level-left">
                            <div className="level-item">{name}</div>
                          </div>
                          <div className="level-right">
                            <div className="level-item">
                              <Link to={`/producten/${product.id}`} className="button is-info">
                                Info
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </>
        );
        exerciseInfo = (
          <div className="box with-shadow">
            <div className="columns">
              <div className="column is-6">
                <h2 className="title is-3">{name}</h2>
                <figure className="image">
                  <img src={imageUrls[0]} alt={name} />
                </figure>
                <h3 className="title is-4">Beschrijving</h3>
                <div className="content">
                  <Markdown>{description}</Markdown>
                </div>
              </div>
              <div className="column is-6">
                <div>{exerciseProducts}</div>
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <Page title="Oefeningen">
        <div className="columns exercises">
          <div className="column is-3-tablet is-3-desktop">
            <div className="field">
              <label className="label" htmlFor="selector">
                Kies uw toestel
              </label>
              <div className="control">
                <div className="select">
                  <select
                    id="product-selector"
                    value={filterProductId}
                    onChange={event => {
                      this.setState({ filterProductId: event.target.value });
                    }}
                  >
                    <option value="">Alle Producten</option>
                    {products.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <aside className="menu">
              <p className="menu-label">
                <strong>Keuze uit:</strong>
              </p>
              <ul className="menu-list">
                {filterProductId === "" &&
                  exercises
                    .sort(function(a, b) {
                      return new Date(b.date).getTime() - new Date(a.date).getTime();
                    })
                    .slice(0, 5)
                    .map(({ id, name }) => {
                      return (
                        <li key={id}>
                          <Link to={`/oefeningen/${id}`} className={selectedExerciseId === id ? "is-active" : ""}>
                            {name}
                          </Link>
                        </li>
                      );
                    })}
                {filterProductId !== "" &&
                  exercises
                    .filter(exercise => {
                      if (filterProductId === "") return true;
                      return exercise.products.find(product => product.id === filterProductId);
                    })
                    .map(({ id, name }) => {
                      return (
                        <li key={id}>
                          <Link to={`/oefeningen/${id}`} className={selectedExerciseId === id ? "is-active" : ""}>
                            {name}
                          </Link>
                        </li>
                      );
                    })}
              </ul>
              <p className="more">
                Meer oefeningen? <br /> Bezoek:{" "}
                <a href="http://www.starthealthy.be" target="_blank">
                  Starthealthy
                </a>
              </p>
            </aside>
          </div>
          <div className="column">{exerciseInfo}</div>
        </div>
      </Page>
    );
  }
}

export default Exercises;
