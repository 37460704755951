import * as React from "react";
import "./TeamList.scss";
import { Component } from "react";
import { RouteComponentProps, Link } from "@reach/router";
import Sidebar from "./Sidebar";
import { Data, Employee, Faq } from "../Data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Page from "../Page";
import { contact } from "../Api";
import Markdown from "../Markdown";

type Props = {
  data: Data;
};

type State = {
  faq: Faq[];
  team: Employee[];
};

export default class FaqComponent extends Component<Props & RouteComponentProps, State> {
  state: State = {
    faq: [],
    team: [],
  };

  async componentDidMount() {
    const faq = await this.props.data.getFaq();
    const team = await this.props.data.getTeam();
    this.setState({ faq: faq, team: team });
  }

  render() {
    const { faq, team } = this.state;

    return (
      <Page title="FAQ">
        <div className="columns">
          <div className="column is-3-tablet is-2-desktop">
            <Sidebar team={team} />
          </div>
          <div className="column is-offset-1-desktop">
            {faq
              .sort((a, b) => b.priority - a.priority)
              .map(faq => {
                return (
                  <div style={{ marginBottom: "15px" }}>
                    <h3 style={{ fontSize: "18px", color: "black", fontWeight: "bold" }}>
                      <Markdown>{faq.question}</Markdown>
                    </h3>
                    <div className="content" style={{ color: "#5a6769" }}>
                      <Markdown>{faq.answer}</Markdown>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Page>
    );
  }
}
