import * as React from "react";
import "./Footer.scss";
import { FunctionComponent } from "react";
import { Link, Match } from "@reach/router";

type Props = {};

const FooterContent: FunctionComponent<Props> = () => {
  return (
            <div className="container">
              <div className="content has-text-centered">
                <div className="level">
                  <div className="level-left">
                    <div className="level-item">
                      <strong>Get-Outt</strong>
                    </div>
                    <div className="level-item">Copyright {new Date().getFullYear()}</div>
                    <div className="level-item">
                      <a href="/privacy" style={{textDecoration: "underline"}}>
                        Privacy Statement
                      </a>
                    </div>
                  </div>
                  <div className="level-right">
                    <p>
                      <strong>Website Verantwoordelijke. </strong>
                      <a href="http://Dri3m.com" target="_blank" style={{textDecoration: "underline"}}>
                        Dri3m.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
  );
};

export default FooterContent;
