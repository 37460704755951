import * as React from "react";
import { FunctionComponent } from "react";
import ReactMarkdown from "react-markdown";
var showdown = require("showdown"),
  converter = new showdown.Converter();

type Props = {
  children: string;
};

const Markdown: FunctionComponent<Props> = props => {
  return <ReactMarkdown source={props.children.replace(/__(.*)__/, "<u>$1</u>")} escapeHtml={false} />;
};

export default Markdown;
