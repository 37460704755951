import * as React from "react";
import "./Checkout.scss";
import { Component } from "react";
import { connect } from "react-redux";
import { toggleCart } from "./Reducers/GeneralReducer";
import { CartItem } from "./Data";
import { removeFromCart, changeAmount, resetCart } from "./Reducers/CartReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { finishCheckout } from "./Api";
import { format } from "./currency";
import validator from 'validator';

type State = {
  step: number;
  email: string;
  firstname: string;
  lastname: string;
  address: string;
  number: string;
  postal: string;
  city: string;
  mobile: string;
  btw: string;
  remarks: string;
  choice: string;
  country: string;
  choiceUpdated: boolean;
  response: null | string;
};

type StateFromProps = {
  show: boolean;
  items: CartItem[];
};

type DispatchFromProps = {
  toggleCart: () => void;
  resetCart: () => void;
  removeFromCart: (id: string) => void;
  changeAmount: (id: string, amount: number) => void;
};

class Checkout extends Component<StateFromProps & DispatchFromProps, State> {
  state: State = {
    step: 0,
    email: "",
    firstname: "",
    lastname: "",
    address: "",
    number: "",
    postal: "",
    city: "",
    remarks: "",
    mobile: "",
    btw: "",
    choice: "Kopen - Afrekenen",
    country: "BE",
    choiceUpdated: false,
    response: null,
  };

  price(items: CartItem[]) {
    var total = 0;

    for (var i = 0; i < items.length; i++) {
      total += items[i].product.price * items[i].amount;
    }

    return total;
  }

  renderStep0() {
    const { items, toggleCart, removeFromCart, changeAmount } = this.props;
    const { step } = this.state;

    return (
      <>
        <div className="top">
          <h2 className="title is-3 has-text-centered">Mijn Winkelwagen</h2>
        </div>
        <div className="content">
          {items.length !== 0 && (
            <div className="cart-header">
              <span>Aantal</span>
              <span>Prijs</span>
            </div>
          )}
          {items.length === 0 && (
            <div>
              <p>Er werden nog geen items toegevoegd aan de winkelwagen.</p>
            </div>
          )}
          {items.map(item => (
            <div key={item.product.id} className="cart-row">
              <img src={item.product.imageUrls[0]} alt={item.product.name}/>
              <span className="name">{item.product.name}</span>
              <input
                type="number"
                className="input"
                value={item.amount}
                onChange={e => {
                  changeAmount(item.product.id, parseInt(e.target.value));
                }}
                min={1}
                max={100}
              />
              <span className="price">{format(item.product.price * item.amount, item.product.tva)}{" "}</span>
              <button
                className="delete is-medium"
                onClick={() => {
                  removeFromCart(item.product.id);
                }}
              />
            </div>
          ))}
        </div>
        <div className="bottom">
          {items.length !== 0 && (
            <button
              className="button is-primary"
              onClick={() =>
                this.setState({
                  step: step + 1,
                })
              }
            >
              Volgende
            </button>
          )}
          <button className="button is-light" onClick={() => toggleCart()}>
            Verder Winkelen
          </button>
        </div>
      </>
    );
  }

  renderStep1() {
    const { step, email, firstname, lastname, address, number, postal, city, mobile, btw, remarks, choice, country } = this.state;
    const { items, resetCart } = this.props;
    return (
      <>
        <div className="top">
          <h2 className="title is-3 has-text-centered">Mijn Gegevens</h2>
        </div>
        <div className="content">
          <form onSubmit={(e) => {
            e.preventDefault();

            if(mobile.trim() !== "" && !validator.isMobilePhone(mobile)){
              this.setState({response: "GSM is niet in het correcte formaat"});
              return;
            }

            this.setState({
              step: step + 1,
            });

            resetCart();

            finishCheckout(email, firstname, lastname, items, btw, remarks, choice, address + " " + number, postal, city, mobile, country);
          }}>
            <div className="field">
              {this.state.response}
            </div>
            <div className="field-row">
              <div className="field">
                <label className="label">Voornaam</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={firstname}
                    onChange={e => this.setState({ firstname: e.target.value })}
                    required
                    autoFocus
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Achternaam</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={lastname}
                    onChange={e => this.setState({ lastname: e.target.value })}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">Mijn Emailadres</label>
              <div className="control has-icons-left">
                <input
                  className="input"
                  type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="Ongeldig email-adres opgegeven."
                  placeholder="Bvb. naam@email.be"
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                  required
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon="envelope"/>
                </span>
              </div>
            </div>
            <div className="field-row">
              <div className="field">
                <label className="label">Adres</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={address}
                    onChange={e => this.setState({ address: e.target.value })}
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Nummer</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={number}
                    onChange={e => this.setState({ number: e.target.value })}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="field-row">
              <div className="field">
                <label className="label">Postcode</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={postal}
                    onChange={e => this.setState({ postal: e.target.value })}
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Stad</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={city}
                    onChange={e => this.setState({ city: e.target.value })}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">Land</label>
              <div className="control">
                <select className="input" value={country} onChange={e => this.setState({ country: e.target.value })}
                        required>
                  <option value="BE">België</option>
                  <option value="NL">Nederland</option>
                  <option value="FR">France</option>
                  <option value="DE">Germany</option>
                  <option value="AF">Afghanistan</option>
                  <option value="AX">Åland Islands</option>
                  <option value="AL">Albania</option>
                  <option value="DZ">Algeria</option>
                  <option value="AS">American Samoa</option>
                  <option value="AD">Andorra</option>
                  <option value="AO">Angola</option>
                  <option value="AI">Anguilla</option>
                  <option value="AQ">Antarctica</option>
                  <option value="AG">Antigua and Barbuda</option>
                  <option value="AR">Argentina</option>
                  <option value="AM">Armenia</option>
                  <option value="AW">Aruba</option>
                  <option value="AU">Australia</option>
                  <option value="AT">Austria</option>
                  <option value="AZ">Azerbaijan</option>
                  <option value="BS">Bahamas</option>
                  <option value="BH">Bahrain</option>
                  <option value="BD">Bangladesh</option>
                  <option value="BB">Barbados</option>
                  <option value="BY">Belarus</option>
                  <option value="BZ">Belize</option>
                  <option value="BJ">Benin</option>
                  <option value="BM">Bermuda</option>
                  <option value="BT">Bhutan</option>
                  <option value="BO">Bolivia, Plurinational State of</option>
                  <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                  <option value="BA">Bosnia and Herzegovina</option>
                  <option value="BW">Botswana</option>
                  <option value="BV">Bouvet Island</option>
                  <option value="BR">Brazil</option>
                  <option value="IO">British Indian Ocean Territory</option>
                  <option value="BN">Brunei Darussalam</option>
                  <option value="BG">Bulgaria</option>
                  <option value="BF">Burkina Faso</option>
                  <option value="BI">Burundi</option>
                  <option value="KH">Cambodia</option>
                  <option value="CM">Cameroon</option>
                  <option value="CA">Canada</option>
                  <option value="CV">Cape Verde</option>
                  <option value="KY">Cayman Islands</option>
                  <option value="CF">Central African Republic</option>
                  <option value="TD">Chad</option>
                  <option value="CL">Chile</option>
                  <option value="CN">China</option>
                  <option value="CX">Christmas Island</option>
                  <option value="CC">Cocos (Keeling) Islands</option>
                  <option value="CO">Colombia</option>
                  <option value="KM">Comoros</option>
                  <option value="CG">Congo</option>
                  <option value="CD">Congo, the Democratic Republic of the</option>
                  <option value="CK">Cook Islands</option>
                  <option value="CR">Costa Rica</option>
                  <option value="CI">Côte d'Ivoire</option>
                  <option value="HR">Croatia</option>
                  <option value="CU">Cuba</option>
                  <option value="CW">Curaçao</option>
                  <option value="CY">Cyprus</option>
                  <option value="CZ">Czech Republic</option>
                  <option value="DK">Denmark</option>
                  <option value="DJ">Djibouti</option>
                  <option value="DM">Dominica</option>
                  <option value="DO">Dominican Republic</option>
                  <option value="EC">Ecuador</option>
                  <option value="EG">Egypt</option>
                  <option value="SV">El Salvador</option>
                  <option value="GQ">Equatorial Guinea</option>
                  <option value="ER">Eritrea</option>
                  <option value="EE">Estonia</option>
                  <option value="ET">Ethiopia</option>
                  <option value="FK">Falkland Islands (Malvinas)</option>
                  <option value="FO">Faroe Islands</option>
                  <option value="FJ">Fiji</option>
                  <option value="FI">Finland</option>
                  <option value="GF">French Guiana</option>
                  <option value="PF">French Polynesia</option>
                  <option value="TF">French Southern Territories</option>
                  <option value="GA">Gabon</option>
                  <option value="GM">Gambia</option>
                  <option value="GE">Georgia</option>
                  <option value="GH">Ghana</option>
                  <option value="GI">Gibraltar</option>
                  <option value="GR">Greece</option>
                  <option value="GL">Greenland</option>
                  <option value="GD">Grenada</option>
                  <option value="GP">Guadeloupe</option>
                  <option value="GU">Guam</option>
                  <option value="GT">Guatemala</option>
                  <option value="GG">Guernsey</option>
                  <option value="GN">Guinea</option>
                  <option value="GW">Guinea-Bissau</option>
                  <option value="GY">Guyana</option>
                  <option value="HT">Haiti</option>
                  <option value="HM">Heard Island and McDonald Islands</option>
                  <option value="VA">Holy See (Vatican City State)</option>
                  <option value="HN">Honduras</option>
                  <option value="HK">Hong Kong</option>
                  <option value="HU">Hungary</option>
                  <option value="IS">Iceland</option>
                  <option value="IN">India</option>
                  <option value="ID">Indonesia</option>
                  <option value="IR">Iran, Islamic Republic of</option>
                  <option value="IQ">Iraq</option>
                  <option value="IE">Ireland</option>
                  <option value="IM">Isle of Man</option>
                  <option value="IL">Israel</option>
                  <option value="IT">Italy</option>
                  <option value="JM">Jamaica</option>
                  <option value="JP">Japan</option>
                  <option value="JE">Jersey</option>
                  <option value="JO">Jordan</option>
                  <option value="KZ">Kazakhstan</option>
                  <option value="KE">Kenya</option>
                  <option value="KI">Kiribati</option>
                  <option value="KP">Korea, Democratic People's Republic of</option>
                  <option value="KR">Korea, Republic of</option>
                  <option value="KW">Kuwait</option>
                  <option value="KG">Kyrgyzstan</option>
                  <option value="LA">Lao People's Democratic Republic</option>
                  <option value="LV">Latvia</option>
                  <option value="LB">Lebanon</option>
                  <option value="LS">Lesotho</option>
                  <option value="LR">Liberia</option>
                  <option value="LY">Libya</option>
                  <option value="LI">Liechtenstein</option>
                  <option value="LT">Lithuania</option>
                  <option value="LU">Luxembourg</option>
                  <option value="MO">Macao</option>
                  <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                  <option value="MG">Madagascar</option>
                  <option value="MW">Malawi</option>
                  <option value="MY">Malaysia</option>
                  <option value="MV">Maldives</option>
                  <option value="ML">Mali</option>
                  <option value="MT">Malta</option>
                  <option value="MH">Marshall Islands</option>
                  <option value="MQ">Martinique</option>
                  <option value="MR">Mauritania</option>
                  <option value="MU">Mauritius</option>
                  <option value="YT">Mayotte</option>
                  <option value="MX">Mexico</option>
                  <option value="FM">Micronesia, Federated States of</option>
                  <option value="MD">Moldova, Republic of</option>
                  <option value="MC">Monaco</option>
                  <option value="MN">Mongolia</option>
                  <option value="ME">Montenegro</option>
                  <option value="MS">Montserrat</option>
                  <option value="MA">Morocco</option>
                  <option value="MZ">Mozambique</option>
                  <option value="MM">Myanmar</option>
                  <option value="NA">Namibia</option>
                  <option value="NR">Nauru</option>
                  <option value="NP">Nepal</option>
                  <option value="NC">New Caledonia</option>
                  <option value="NZ">New Zealand</option>
                  <option value="NI">Nicaragua</option>
                  <option value="NE">Niger</option>
                  <option value="NG">Nigeria</option>
                  <option value="NU">Niue</option>
                  <option value="NF">Norfolk Island</option>
                  <option value="MP">Northern Mariana Islands</option>
                  <option value="NO">Norway</option>
                  <option value="OM">Oman</option>
                  <option value="PK">Pakistan</option>
                  <option value="PW">Palau</option>
                  <option value="PS">Palestinian Territory, Occupied</option>
                  <option value="PA">Panama</option>
                  <option value="PG">Papua New Guinea</option>
                  <option value="PY">Paraguay</option>
                  <option value="PE">Peru</option>
                  <option value="PH">Philippines</option>
                  <option value="PN">Pitcairn</option>
                  <option value="PL">Poland</option>
                  <option value="PT">Portugal</option>
                  <option value="PR">Puerto Rico</option>
                  <option value="QA">Qatar</option>
                  <option value="RE">Réunion</option>
                  <option value="RO">Romania</option>
                  <option value="RU">Russian Federation</option>
                  <option value="RW">Rwanda</option>
                  <option value="BL">Saint Barthélemy</option>
                  <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                  <option value="KN">Saint Kitts and Nevis</option>
                  <option value="LC">Saint Lucia</option>
                  <option value="MF">Saint Martin (French part)</option>
                  <option value="PM">Saint Pierre and Miquelon</option>
                  <option value="VC">Saint Vincent and the Grenadines</option>
                  <option value="WS">Samoa</option>
                  <option value="SM">San Marino</option>
                  <option value="ST">Sao Tome and Principe</option>
                  <option value="SA">Saudi Arabia</option>
                  <option value="SN">Senegal</option>
                  <option value="RS">Serbia</option>
                  <option value="SC">Seychelles</option>
                  <option value="SL">Sierra Leone</option>
                  <option value="SG">Singapore</option>
                  <option value="SX">Sint Maarten (Dutch part)</option>
                  <option value="SK">Slovakia</option>
                  <option value="SI">Slovenia</option>
                  <option value="SB">Solomon Islands</option>
                  <option value="SO">Somalia</option>
                  <option value="ZA">South Africa</option>
                  <option value="GS">South Georgia and the South Sandwich Islands</option>
                  <option value="SS">South Sudan</option>
                  <option value="ES">Spain</option>
                  <option value="LK">Sri Lanka</option>
                  <option value="SD">Sudan</option>
                  <option value="SR">Suriname</option>
                  <option value="SJ">Svalbard and Jan Mayen</option>
                  <option value="SZ">Swaziland</option>
                  <option value="SE">Sweden</option>
                  <option value="CH">Switzerland</option>
                  <option value="SY">Syrian Arab Republic</option>
                  <option value="TW">Taiwan, Province of China</option>
                  <option value="TJ">Tajikistan</option>
                  <option value="TZ">Tanzania, United Republic of</option>
                  <option value="TH">Thailand</option>
                  <option value="TL">Timor-Leste</option>
                  <option value="TG">Togo</option>
                  <option value="TK">Tokelau</option>
                  <option value="TO">Tonga</option>
                  <option value="TT">Trinidad and Tobago</option>
                  <option value="TN">Tunisia</option>
                  <option value="TR">Turkey</option>
                  <option value="TM">Turkmenistan</option>
                  <option value="TC">Turks and Caicos Islands</option>
                  <option value="TV">Tuvalu</option>
                  <option value="UG">Uganda</option>
                  <option value="UA">Ukraine</option>
                  <option value="AE">United Arab Emirates</option>
                  <option value="GB">United Kingdom</option>
                  <option value="US">United States</option>
                  <option value="UM">United States Minor Outlying Islands</option>
                  <option value="UY">Uruguay</option>
                  <option value="UZ">Uzbekistan</option>
                  <option value="VU">Vanuatu</option>
                  <option value="VE">Venezuela, Bolivarian Republic of</option>
                  <option value="VN">Viet Nam</option>
                  <option value="VG">Virgin Islands, British</option>
                  <option value="VI">Virgin Islands, U.S.</option>
                  <option value="WF">Wallis and Futuna</option>
                  <option value="EH">Western Sahara</option>
                  <option value="YE">Yemen</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabwe</option>
                </select>
              </div>
            </div>
            <div className="field-row">
              <div className="field">
                <label className="label">GSM (Optioneel)</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={mobile}
                    onChange={e => this.setState({ mobile: e.target.value })}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">BTW Nummer (Optioneel)</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={btw}
                    onChange={e => this.setState({ btw: e.target.value })}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label className="label">Opmerking</label>
              <div className="control">
                <textarea
                  className="textarea"
                  value={remarks}
                  onChange={e => this.setState({ remarks: e.target.value })}
                  placeholder=""
                />
              </div>
            </div>

            <div className="field">
              {(this.price(items) > 1000) && <label className="label">Offerte of direct afrekenen?</label>}
              <div className="control">
                {(this.price(items) > 1000) && <label className="radio">
                  <input type="radio" name="choice" value="Kopen - Offerte" checked={choice === "Kopen - Offerte"}
                         onChange={e => this.setState({
                           choice: e.currentTarget.value,
                         })}/>
                  Offerte
                </label>}
                <label className="radio">
                  <input type="radio" name="choice" value="Kopen - Afrekenen" checked={choice === "Kopen - Afrekenen"}
                         onChange={e => this.setState({
                           choice: e.currentTarget.value,
                         })}/>
                  Direct Kopen
                </label>
              </div>
            </div>
            <div className="bottom">
              <button
                className="button is-primary"
              >
                Verzenden
              </button>
              <button
                className="button is-light"
                onClick={() =>
                  this.setState({
                    step: step - 1,
                  })
                }
              >
                Vorige
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }

  renderStep2() {
    const { toggleCart } = this.props;
    return (
      <>
        <div className="top">
          <h2 className="title is-3 has-text-centered">Bedankt voor uw aanvraag!</h2>
        </div>
        <div className="content">
          <p>
            Uw aanvraag/aankoop is goed ontvangen.
            <br/><br/>
            Er is tevens een bevestigingsmail naar u gestuurd, check zeker ook uw spam-mailbox
            <br /><br/>
            U hoort spoedig van ons.
          </p>
        </div>
        <div className="bottom">
          <button
            className="button is-light"
            onClick={() => {
              toggleCart();
              this.setState({
                step: 0,
              });
            }}
          >
            Sluiten
          </button>
        </div>
      </>
    );
  }

  componentDidUpdate() {
    if(!this.state.choiceUpdated && this.price(this.props.items) > 1000) {
      this.setState({choice: "Kopen - Offerte", choiceUpdated: true});
    }
  }

  render() {
    const { show, toggleCart } = this.props;
    const { step } = this.state;

    return (
      <div className="checkout">
        <div className={show ? "modal is-active" : "modal"}>
          <div className="modal-background"/>
          <div className="modal-content">
            {step === 0 && this.renderStep0()}
            {step === 1 && this.renderStep1()}
            {step === 2 && this.renderStep2()}
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={() => toggleCart()}/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  show: state.general.showCart,
  items: state.cart.items,
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleCart: () => dispatch(toggleCart()),
  resetCart: () => dispatch(resetCart()),
  removeFromCart: (id: string) => dispatch(removeFromCart(id)),
  changeAmount: (id: string, amount: number) => dispatch(changeAmount(id, amount)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Checkout);
