import * as React from "react";
import { Component } from "react";
import { RouteComponentProps, globalHistory, Link, Redirect } from "@reach/router";
import { Data, Landing } from "./Data";
import "./Landingpage.scss";
import FooterContent from "./FooterContent";
import CookieItem from "./CookieItem";
import logo from "./img/logo.png";

type Props = {
  data: Data;
  landing: Landing;
};

type State = {
  video: boolean;
  firstVisit: boolean;
  redirect: boolean;
};

export default class Landingpage extends Component<Props & RouteComponentProps, State> {
  state: State = {
    video: false,
    firstVisit: false,
    redirect: false,
  };

  async componentDidMount() {
    const firstVisit = localStorage.getItem("visit") === null;

    if (firstVisit) {
      localStorage.setItem("visit", "true");
    }

    this.setState({ firstVisit: firstVisit });

    const timer = setTimeout(() => {
      clearTimeout(timer);
      this.setState({ redirect: true });
    }, 25 * 1000);
  }

  toggleVideo() {
    this.setState({ video: !this.state.video });
  }

  render() {
    let { landing } = this.props;
    let { video, firstVisit } = this.state;

    return (
      <div className="landing-container">
        {this.state.redirect ? (
          // eslint-disable-next-line react/jsx-no-undef
          <Redirect to="/home" noThrow />
        ) : null}
        <img src={logo} className="logo-landing" alt="Get-Outt Logo" />
        <div>
          <video
            id="video_background"
            height="100%"
            width="100%"
            src={landing.slowmotion ? landing.slowmotion.url : ""}
            autoPlay
            loop
            muted
          ></video>
        </div>
        <div className="overlay">
          <div className="box left">
            <div style={{ marginBottom: "15px" }}>
              {firstVisit
                ? "Het is de eerste keer dat je onze website bezoekt. Gelieve te kiezen welke cookies je accepteert."
                : "Je hebt al eerder onze website bezocht, deze cookies worden momenteel gebruikt."}
            </div>
            <CookieItem
              data={this.props.data}
              name={"Youtube"}
              id={"youtube"}
              extra={
                "Deze cookie wordt gebruikt om verschillende videos van youtube in te laden. Bij toelating zullen Fitness-oefeningen in een filmpje getoond worden, dus aanbevolen"
              }
            />
            <CookieItem
              data={this.props.data}
              name={"Facebook"}
              id={"facebook"}
              extra={
                "Deze cookie wordt gebruikt om de facebook widget te kunnen inladen. Bij toelating zal u de posts over Get-outt in Facebook ook kunnen lezen, uw keuze"
              }
            />
          </div>
          <div className="middle">
            <div className="top">
              <div className="button is-primary with-shadow has-hover" onClick={() => this.toggleVideo()}>
                Speel introductie film
              </div>
            </div>
            <Link to="/home" className="button is-primary with-shadow has-hover">
              Ga naar de detail website van Get-Outt
            </Link>
          </div>
          <div className="box right"></div>
          <footer className="footer landing">
            <FooterContent />
          </footer>
        </div>

        <div className="checkout">
          <div className={video ? "modal is-active" : "modal"}>
            <div className="modal-background" />
            <div className="modal-content" style={{ backgroundColor: "transparent" }}>
              {video ? <video src={landing.movie ? landing.movie.url : ""} autoPlay controls></video> : null}
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={() => this.toggleVideo()} />
          </div>
        </div>
      </div>
    );
  }
}
